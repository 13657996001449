import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Wrapper, Container, Column, Row } from "../theme"
import { Responsive } from "../theme/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StyledDiv = styled.div`
  ${Responsive.sm`
  width: 90%;
  margin: 0 auto;
`}
  ${Responsive.lg`
  width: 80%;
`}
`

const StyledRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 50px 0 0 0;
    align-items: center;
  `}
  ${Responsive.lg`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`}
`

const WpPost = ({
  data: {
    wpPost: { title, content, featuredImage, id },
  },
}) => {
  const WpImage = getImage(featuredImage.node.localFile.childImageSharp)

  return (
    <Layout title={title} type="blogPost" className={`post-${id}`}>
      <Wrapper white>
        <Container>
          <Column width="90%" margin="0 auto">
            <GatsbyImage
              image={WpImage}
              alt="CheshTech William Cheshier"
              placeholder="blurred"
            />
            <StyledRow>
              <StyledDiv dangerouslySetInnerHTML={{ __html: content }} />
            </StyledRow>
          </Column>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default WpPost

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      featuredImage {
        node {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`
// <GatsbyImage
//   image={
//     featuredImage.node.id.localFile.childImageSharp.gatsbyImageData
//   }
//   alt="CheshTech William Cheshier"
//   placeholder="blurred"
// />
